import React from "react";
import { graphql } from "gatsby";

import Seo from "../components/Seo";
import Layout from "../templates/Layout";
import Text from "../components/Text/Text";
import Card, { CardContainer } from "../components/Card";

const IndexPage = ({ data }) => {
  const {
    allMdx: { nodes: mdxNodes },
  } = data;
  return (
    <>
      <Seo />
      <Layout>
        <Text>
          <main style={{ padding: "48px" }}>
            <article>
              <CardContainer>
                {mdxNodes.map(({ frontmatter }) => (
                  <Card key={frontmatter.seoTitle} data={frontmatter} />
                ))}
              </CardContainer>
            </article>
          </main>
        </Text>
      </Layout>
    </>
  );
};

export const query = graphql`
  {
    allMdx(
      filter: { frontmatter: { parent: { eq: null } } }
      sort: { fields: frontmatter___order, order: ASC }
    ) {
      nodes {
        frontmatter {
          excerpt
          seoTitle
          path
          postImage {
            childImageSharp {
              fluid(maxWidth: 480) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
